.App {
  text-align: center;
  background-color: black;
}
head {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Google Chrome, Microsoft Edge, and Safari */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  background-color: transparent; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #000; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Rounded corners for the scrollbar thumb */
}

/* For Mozilla Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000 transparent;
}

.my-scrollable-div::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.my-scrollable-div::-webkit-scrollbar-thumb {
  /* background-color: #000; */
  border-radius: 3px;
}

/* Firefox */
.my-scrollable-div {
  scrollbar-width: thin;
  /* scrollbar-color: #000 transparent; */
}

/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border-top: 4px solid #dcff51;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.select-container {
  margin-bottom: 1rem; /* Add some space to prevent overlap */
  position: relative;
}

.select-container select {
  z-index: 10; /* Ensures the dropdown is above other elements */
}

.image-container {
  position: relative;
  overflow: hidden; /* Keeps the image within the frame */
}

.product-image {
  transition: transform 0.1s ease-in-out; /* Smooth transition on hover and leave */
}

/* .zoomed {
  transform: scale(2); 
} */