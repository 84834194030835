@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: black;
}

main {
  display: block;
  clear: both;
  background-color: black;
}

html {
  background-color: black;
}

/* For Google Chrome, Microsoft Edge, and Safari */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  background-color: transparent; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Rounded corners for the scrollbar thumb */
}

/* For Mozilla Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

.my-scrollable-div::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.my-scrollable-div::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 3px;
}

/* Firefox */
.my-scrollable-div {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border-top: 4px solid #dcff51;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
